import React from 'react';
import styles from './part3.module.css';
import { useState, useEffect, useRef} from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { useNavigate } from 'react-router-dom';

export default function Part3() {
    const navigate = useNavigate();
    const [detail_idx, set_detail_idx] = useState(0);
    const [MainOpacity, setMainOpacity] = useState('0,0,0,1')
    // const [FinishOpacity, setFinishOpacity] = useState(1)
    const questionAudioRef = useRef(null);
    const [CanRecord, setCanRecord] = useState(false);
    const [record_text, set_record_text] = useState('Start Recording');
    const [displayFinish, setDisplayFinish] = useState('none');
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null); // 存储录制后的音频 URL
    const [audioBlob, setAudioBlob] = useState(null); // 存储录制的音频数据
    const audioRef = useRef(null); // 用于控制audio元素
    const [last_audio, set_last_audio] = useState(false)
    const [RecordButtonBackground, setRecordButtonBackground] = useState('#d9d9d9'); 
    const [NextButtonBackground, setNextButtonBackground] = useState('#d9d9d9'); 
    const [QuestionContent, setQuestionContent] = useState('Click to show the question'); 
    const [FinishRecording, setFinishRecording] = useState(false);
    const [nextText, setNextText] = useState('Next');
    const timerRef = useRef(null); 
    const [currentTime, setCurrentTime] = useState(0);

    const Back = () => {
      navigate('/mainpage')
    }
    const playAudio = () => {
      
        if (questionAudioRef.current && !isRecording) {
          // console.log("1111")
          setCanRecord(false);
          questionAudioRef.current.play(); // 示例：点击时播放音频
        }
    };
    const handleAudioEnded = () => {
        // 音频播放结束后，设置按钮背景颜色
        setCanRecord(true);
        setRecordButtonBackground('#505F98'); // 更改背景色为 #505F98
      };
    useEffect(() => {
      const audio_path = `https://koispeak.com/static/library/audio_output/${sessionStorage.getItem("questionType")}/part3/${JSON.parse(sessionStorage.getItem("part3_question_idx_list"))[detail_idx]}.mp3`;
      questionAudioRef.current.src = audio_path;
      // console.log("111", JSON.parse(sessionStorage.getItem("part3_question_idx_list")))
      const getMediaAndPlay = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          // 此处假设已经有了audioRef或类似的用于控制音频的ref
          playAudio();
        } catch (error) {
          console.error('Error accessing the microphone:', error);
        }
      };
  
    getMediaAndPlay();
        
    }, []);

    const start_record_button = async () => {
      // console.log("1111")
      if (CanRecord){
        if (!last_audio) {
          if (isRecording) { 
              setIsRecording(false); // 更新状态为结束录音
              setFinishRecording(true);
          } else {
            // 启动录音
            setIsRecording(true);
            set_record_text("Stop Recording");
            // setFinishRecording(true);
          }
        }
      }
      
       
      };

    const onStop = (audioData) => {
        // console.log('录音数据:', audioData);
        const blob = audioData.blob;
        const url = audioData.url;
        // setFinishRecording(false);
        // for test
        setAudioBlob(blob);
        setAudioUrl(url); // 生成音频的 URL

        set_record_text("Uploading Recording");
        setRecordButtonBackground("#d9d9d9")
        const question_list = JSON.parse(sessionStorage.getItem("part3_questions"))
        // console.log("length", question_list.length-1)
        // console.log("detail_idx", detail_idx)
        if (detail_idx ==question_list.length-1) {
          // console.log("1111")
          set_last_audio(true);
          // console.log("last_audio", last_audio)
        }
        
        // console.log("last_audio", last_audio)
        const formData = new FormData();
        formData.append('audio', blob);
        formData.append('question', question_list[detail_idx]);
        formData.append('detail_idx', detail_idx);
        formData.append('last_audio', last_audio);
        // formData.append('username', "pandafanfanfan@gmail.com");
        // formData.append('score', "7");
        formData.append('part_idx', "3");
        formData.append('username', sessionStorage.getItem('email'));
        formData.append('score', sessionStorage.getItem('targetScore'));
        formData.append('question_name', sessionStorage.getItem('question_name'));
        formData.append('question_labels', sessionStorage.getItem('question_labels'));

        fetch('https://koispeak.com/api/utils/upload_audios/', {
          method: 'POST',
          body: formData
        })
        .then(response => response.json()) // 处理返回的 JSON 数据
        .then(responseData => {
          console.log('Upload successful', responseData);
          // 可以根据返回数据执行后续操作
        })
        .catch(error => {
          console.error('Error uploading the recording', error);
          // 可以执行错误处理逻辑
        });
        
        if (!last_audio) {
          setNextButtonBackground("#505F98")
          set_record_text("Start Recording");
          const new_detail_idx = detail_idx + 1;
          set_detail_idx(new_detail_idx);
          const audio_path = `https://koispeak.com/static/library/audio_output/${sessionStorage.getItem("questionType")}/part3/${JSON.parse(sessionStorage.getItem("part3_question_idx_list"))[new_detail_idx]}.mp3`;
          console.log("audio_path", audio_path)
          questionAudioRef.current.src = audio_path;
        }
      
        
      };

    const handleLogic = () => {
      if (last_audio) {
        // setNextText("Next Part");
        // setNextText("Finish Uploading");
        // console.log("1111")
        setNextButtonBackground("#d9d9d9");
        setMainOpacity('0,0,0,.3')
        setDisplayFinish("block")
      } 
    }

    useEffect(() => {
      handleLogic();
    }, [last_audio]); 

    const showQuestion = () => {
        const part3_questions = JSON.parse(sessionStorage.getItem("part3_questions"))
        setQuestionContent(part3_questions[detail_idx]);
    };

    const next_part = () => {
      // console.log("FinishRecording2", FinishRecording)
      if (FinishRecording && !last_audio) {
        setQuestionContent('Click to show the question');
        setCurrentTime(0);
        playAudio();
        setFinishRecording(false);
        setRecordButtonBackground("#d9d9d9");
        setNextButtonBackground("#d9d9d9");
      }
      
  };

    useEffect(() => {
      if (isRecording) {
        timerRef.current = setInterval(() => {
          setCurrentTime((prevTime) => prevTime + 1);
        }, 1000); // 每秒更新
      } else {
        clearInterval(timerRef.current); // 停止录音时清除定时器
        // setCurrentTime(0);
      }
  
      return () => {
        clearInterval(timerRef.current); // 清理定时器
      };
    }, [isRecording]);
    return (
        <div className={styles.main_container} 
        style={{background: MainOpacity}}>
            <audio
                ref={questionAudioRef}
                src="https://koispeak.com/static/audio/check.mp3"  // 替换为你的音频路径
                onEnded={handleAudioEnded}
                />
            <div 
                style={{ display: 'none' }} // 让 div 隐藏，没样式
                onClick={playAudio} // 绑定点击事件
            />
          <div className={styles.rectangle}>
            <span className={styles.koi_speak}>KOI SPEAK</span>
          </div>
          <span className={styles.part_one}>PART THREE</span>
          <span className={styles.interview_and_introduction}>
            INTERVIEW AND INTRODUCTION
          </span>
          <div className={styles.rectangle_1}>
            <div className={styles.ellipse} />
            <div className={styles.rectangle_2} />
          </div>
          <div className={styles.rectangle_3} onClick={showQuestion}>
            <span className={styles.click_to_show}>{QuestionContent}</span>
          </div>
          <div className={styles.flex_row} >
            <AudioReactRecorder
              state={isRecording ? RecordState.START : RecordState.STOP}
              onStop={onStop}
              canvasHeight={96}
              canvasWidth={896}
              backgroundColor="rgb(255, 255, 255)"
              style={{
                width: '896px !important',
                height: '96px !important',
              }}
            />
            <div className={styles.mic} />
            {/* <div className={styles.line} /> */}
          </div>
          <span className={styles.span}>{`${Math.floor(currentTime / 60)}:${String(currentTime % 60).padStart(2, "0")}`}</span>
          <div className={styles.flex_row_cc}>
            <button className={styles.frame} 
            onClick={start_record_button}
            style={{background: RecordButtonBackground}}>
              <span className={styles.start_recording}>{record_text}</span>
              <audio ref={audioRef} src={audioUrl} />
            </button>
            {/* {audioUrl && (
              <a
                href={audioUrl}        // Set the href to the generated audio URL
                download="recording.mp3"  // Set the filename you want for the download
                className="download-link"
              >
                Download Recording
              </a>
            )} */}
            <div className={styles.rectangle_4} onClick={next_part} style={{background: NextButtonBackground}}>
              <span className={styles.next}>{nextText}</span>
              <div className={styles.flex_column}>
                <div className={styles.line_5} />
                <div className={styles.line_6} />
              </div>
            </div>
          </div>
          <div className={styles.finish_main_container} style={{display: displayFinish, opacity: 1}}>
            <div className={styles.finish_icon}>
              <div className={styles.finish_group} />
              <span className={styles.finish_koi_speak}>
                KOI
                <br />
                SPEAK
              </span>
            </div>
            <span className={styles.finish_recordings_uploaded}>
              Your recordings have been uploaded. We will send you the evaluation
              report within around 30 minutes. Thank you for taking the mock test!
            </span>
            <button className={styles.finish_close_page} onClick={Back}>
              <span className={styles.finish_close_page_1}>Back To Main Page</span>
            </button>
            <div className={styles.finish_rectangle} />
          </div>
        </div>
        
      );
}
