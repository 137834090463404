import React, { useState } from 'react';

function TextExpander() {
  const [text, setText] = useState('这是一些初始的文字。');

  const addText = () => {
    // 添加更多文字到现有文字
    setText(text + ' 这是额外添加的一些文字。');
  };

  return (
    <div>
      <button onClick={addText}>添加文字</button>
      <div style={{
        width: '300px',  // 设置一个宽度限制来观察换行效果
        border: '1px solid #ccc',  // 添加边框以更容易看到 div 的大小变化
        padding: '10px',  // 添加一些内边距
        margin: '20px 0'  // 添加一些外边距
      }}>
        {text}
      </div>
    </div>
  );
}

export default TextExpander;