import React, { useState } from 'react';
import styles from './targetScore.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TargetScore() {
  const navigate = useNavigate(); // 创建navigate函数实例
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';  // 获取 email，默认空字符串
  const [targetBand, setTargetBand] = useState("");
  const [scorePlaceholder, setScorePlaceholder] = useState("Please enter your target band");
  const handleTargetBandChange = (e) => {
    setTargetBand(e.target.value);
  };

  const handleScoreFocus = () => {
    setScorePlaceholder('');
  };

  // 当输入框失去焦点时，恢复 placeholder
  const handleScoreBlur = () => {
    if (targetBand === '') { // 如果密码为空，恢复 placeholder
      setScorePlaceholder("Please enter your target band");
    }
  };

  // 验证目标分数的范围和小数规则
  const validateScore = (score) => {
    if (score == "") {
      return '请填写目标分数。';
    }
    const numScore = parseFloat(score);
    if (numScore < 4 || numScore > 9 || numScore % 0.5 !== 0) {
      return '请输入有效的目标分数（4 到 9 之间, 小数点只能是0.5）';
    }
    
    return ''; // 返回空字符串表示验证通过
  };

  // 提交按钮点击事件
  const handleSubmit = () => {
    const errorMessage = validateScore(targetBand);
    if (errorMessage) {
      alert(errorMessage);  // 显示错误信息
      return;
    }

    // 如果验证通过，存储目标分数和 email 到 sessionStorage
    sessionStorage.setItem('targetScore', targetBand);
    sessionStorage.setItem('email', email);

    navigate('/test_equipment');

  };
  return (
    <div className={styles.main_container}>
      <div className={styles.rectangle}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.group} />
      <div className={styles.email_address}>
        <span className={styles.email_input}>
          Please provide us with your e-mail address to receive your IELTS
          speaking evaluation report. Tell us your target band score, so that
          our AI can make customized improvement strategies made just for you.
          Just in case you are interested,
        </span>
        <span className={styles.sample_report}>
          check our sample evaluation report
        </span>
        <span className={styles.dot}>.</span>
      </div>
      <button className={styles.next_button} onClick={handleSubmit}>
        <span className={styles.submit_button}>SUBMIT</span>
      </button>
      <div className={styles.frame}>
        <span className={styles.email}>E-mail</span>
      </div>
      <div className={styles.rectangle_1}>
        <input
          className={styles.enter_email}
          type="text"
          placeholder={email}
          readOnly  // 或者 disabled
        />
      </div>
      <div className={styles.frame_2}>
        <span className={styles.target_band}>Target band</span>
      </div>
      <div className={styles.rectangle_3}>
        <input
            className={styles.enter_target_band}
            type="number"
            step="0.5"  // 只允许 0.5 的小数
            placeholder={scorePlaceholder}
            value={targetBand}
            onChange={handleTargetBandChange}
            onFocus={handleScoreFocus}
            onBlur={handleScoreBlur}  // 如果没有输入，保持 placeholder
          />
      </div>
    </div>
  );
}
