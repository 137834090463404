import React, { useState } from 'react';
import styles from './Register.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNavigation } from '../utils/utils'; 
import eyeOpen from "../assets/login/images/open.png"; // 导入图片
import eyeClose from "../assets/login/images/close.png"; // 导入图片
import axiosInstance from '../axiosInstance/logout';

export default function Register() {
  const navigate = useNavigate();
  const { direct_account, direct_main, direct_mock, direct_history, _, direct_contract, direct_privacy, direct_about } = useNavigation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [Agreed, SetAagreed] = useState(false);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleAagreedChange = (e) => {
    SetAagreed(e.target.checked);
  }

  const ConfimRegistClick = () => {
    if (!Agreed) {
      alert("请同意隐私政策和用户协议");
      return;
    }

    if (!validateEmail(email)) {
      alert("请输入有效邮箱");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    // 注册请求
    axios.post('https://koispeak.com/api/accounts/register/', {
      username: email,
      email: email,
      password: password,
      password2: confirmPassword,
      invite_code: inviteCode,
    })
    .then(response => {
      // 注册成功后发起生成优惠码请求，改为 axiosInstance
      const token = response.data.token;
      axios.post('https://koispeak.com/api/promo_code/generate-promo-code/', {
        user_email: email,
        discount: parseInt(8),
      },
      {
      headers: {
        'Authorization': `Token ${token}`  // 添加 token 到 Authorization header
      }}
      )
      .then(response => {
        // 处理生成优惠码成功
        axios.put('https://koispeak.com/api/user_profiles/profile/add_money/', {
          email: email,
          money: 100
        },{
          headers: {
            'Authorization': `Token ${token}`  // 添加 token 到 Authorization header
          }})
        alert('Registration successful!');
        navigate('/login');
      })
      .catch(error => {
        // 处理生成优惠码失败
        console.log("Axios Error:", error);
        alert('Error creating promo code: ' + error.message);
      });
      
    
    })
    .catch(error => {
      // 处理注册失败
      console.log("Axios Error:", error);
      alert('Registration failed: ' + error.response.data.error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage('Registration failed: ' + JSON.stringify(error.response.data.error));
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    });
  };

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.image_traced} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle}>
        <span className={styles.email_input}>邮箱*</span>
        <div className={styles.rectangle_1}>
          <input
            className={styles.email_example}
            type="email"
            placeholder="example@gmail.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <span className={styles.password}>密码*</span>
        <div className={styles.rectangle_2}>
          <input
            className={styles.password_input}
            type={showPassword ? "text" : "password"} 
            placeholder="********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.show_password} 
              onClick={togglePasswordVisibility}
              style={{
                backgroundImage: `url(${showPassword ? eyeClose : eyeOpen})`, // 根据状态动态更改背景图
              }}>
            </div>
        <span className={styles.confirm_password}>确认密码*</span>
        <div className={styles.rectangle_3}>
          <input
            className={styles.password_confirm}
            type={showConfirmPassword ? "text" : "password"} 
            placeholder="********"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className={styles.show_confirm_password} 
          onClick={toggleConfirmPasswordVisibility}
          style={{
            backgroundImage: `url(${showConfirmPassword ? eyeClose : eyeOpen})`, // 根据状态动态更改背景图
          }}>
        </div>
        {/* <span className={styles.invite_code}>邀请码</span>
        <div className={styles.rectangle_5}>
          <input
            className={styles.abdmo}
            type="text"
            placeholder="ABDMO83"
            required
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
          />
        </div> */}
        <button className={styles.button} onClick={ConfimRegistClick}>
          <span className={styles.register}>注册</span>
        </button>
        {errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
        <div className={styles.agreement_text}>
          <span className={styles.and}>已同意</span>
          <span className={styles.user_agreement} onClick={direct_contract}>《用户协议》</span>
          <span className={styles.and_6}>和</span>
          <span className={styles.user_agreement_7} onClick={direct_privacy}>《隐私政策》</span>
        </div>
        <input className={styles.rectangle_8} 
        type="checkbox" 
        checked={Agreed}
        onChange={handleAagreedChange}/>
      </div>
      <span className={styles.login_account} onClick={handleLoginClick}>登录账号</span>
    </div>
  );
}
