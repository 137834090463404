import React, { useState, useCallback } from 'react';
import ShowQuestions from './show_part13_questions'; // 确保 ShowQuestions 是从外部文件导入
import styles from './Report.module.css';

function Part1Questions({ myProp }) {
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
        return `${paddedMinutes}:${paddedSeconds}`;
    }
    const [currentQuestion, setCurrentQuestion] = useState({ index: 1, text: myProp["问题"]["0"], formatted_audio_duration: formatTime(myProp["音频"][`part${myProp["part_idx"]}_origin_0`]), transcript: myProp["原文"]["0"] });
    const handleQuestionSelect = useCallback((index, text) => {
        const audio_name = `part${myProp["part_idx"]}_origin_${index-1}`;
        console.log("audio_name", audio_name)
        const formatted_audio_duration = formatTime(myProp["音频"][audio_name]);
        const transcript = myProp["原文"][`${index-1}`]
        setCurrentQuestion({ index, text, formatted_audio_duration, transcript });
    }, []); 

    return (
        <div className={styles.transcript}>
            <span className={styles.recordings_transcripts}>Recordings and Transcripts</span>
            <div className={styles.line} />
            <div className={styles.flex_row_aaf}>
                <div className={styles.part_question_list}>
                    <ShowQuestions questions={myProp["问题"]} onQuestionSelect={handleQuestionSelect}/>
                </div>
                <div className={styles.play_box}>
                    <div className={styles.flex_row_bc}>
                        <div className={styles.question}>
                            <span className={styles.question_two}>Question {currentQuestion.index}:</span>
                            <span className={styles.line_break}><br /></span>
                        </div>
                        <span className={styles.bottled_water_or_machines}>
                            {currentQuestion.text}
                        </span>
                    </div>
                    <div className={styles.rectangle_7}>
                        <div className={styles.play_button} />
                        <span className={styles.time_indicator}>00:00 / {currentQuestion.formatted_audio_duration}</span>
                        <div className={styles.ellipse_8} />
                        <div className={styles.line_9} />
                    </div>
                    <div className={styles.work_description}>
                        <span className={styles.work_description_a}>
                            {currentQuestion.transcript}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Part1Questions;