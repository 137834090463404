import React from 'react';
import styles from './part1.module.css';
import { useState, useEffect, useRef} from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { useNavigate } from 'react-router-dom';

export default function Part1() {
    const navigate = useNavigate();
    const [detail_idx, set_detail_idx] = useState(0);
    const questionAudioRef = useRef(null);
    const [record_text, set_record_text] = useState('Start Recording');
    const [CanRecord, setCanRecord] = useState(false);

    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null); // 存储录制后的音频 URL
    const [audioBlob, setAudioBlob] = useState(null); // 存储录制的音频数据
    const audioRef = useRef(null); // 用于控制audio元素
    const [last_audio, set_last_audio] = useState(false)
    const [RecordButtonBackground, setRecordButtonBackground] = useState('#d9d9d9'); 
    const [NextButtonBackground, setNextButtonBackground] = useState('#d9d9d9'); 
    const [QuestionContent, setQuestionContent] = useState('Click to show the question'); 
    const [FinishRecording, setFinishRecording] = useState(false);
    const [nextText, setNextText] = useState('Next');
    const timerRef = useRef(null); 
    const [currentTime, setCurrentTime] = useState(0);

    
    const playAudio = () => {
      
        if (questionAudioRef.current && !isRecording) {
          // console.log("1111")
          setCanRecord(false);
            questionAudioRef.current.play(); // 示例：点击时播放音频
        }
    };
    const handleAudioEnded = () => {
        // 音频播放结束后，设置按钮背景颜色
        setRecordButtonBackground('#505F98'); // 更改背景色为 #505F98
        setCanRecord(true);
      };
    useEffect(() => {
        const randomQuestionIdx = Math.floor(Math.random() * 40); 
        const randomQuestionType = Math.random() > 0.5 ? '套题' : '剑桥';
        // const randomQuestionType = "套题";
        sessionStorage.setItem('questionType', randomQuestionType);
        const fetchData = async () => {
            try {
                const response = await fetch('https://koispeak.com/api/utils/get_question/', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ question_idx: randomQuestionIdx, question_type: randomQuestionType }),
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
        
                const data = await response.json();
                
                sessionStorage.setItem('part1_questions', JSON.stringify(data["part1"]));
                sessionStorage.setItem('part1_question_idx_list', JSON.stringify(data["part1_question_idx_list"]));
                sessionStorage.setItem('part2_questions', JSON.stringify(data["part2"]));
                sessionStorage.setItem('part3_questions', JSON.stringify(data["part3"]));
                sessionStorage.setItem('part3_question_idx_list', JSON.stringify(data["part3_question_idx_list"]));
                sessionStorage.setItem('question_name', data["question_name"]);
                sessionStorage.setItem('question_labels', data["question_labels"]);
                // sessionStorage.setItem('questionType', JSON.stringify(data["questionType"]));
                // console.log("part1_question_idx_list", data["part1_question_idx_list"])
                // console.log("questionType", sessionStorage.getItem("questionType"))
                // console.log("detail_idx", detail_idx)
                // 构造音频文件的路径
                const audio_path = `https://koispeak.com/static/library/audio_output/${sessionStorage.getItem("questionType")}/part1/${JSON.parse(sessionStorage.getItem("part1_question_idx_list"))[detail_idx]}.mp3`;
                questionAudioRef.current.src = audio_path;
                // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                // playAudio();

                const getMediaAndPlay = async () => {
                  try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    // 此处假设已经有了audioRef或类似的用于控制音频的ref
                    playAudio();
                  } catch (error) {
                    console.error('Error accessing the microphone:', error);
                  }
                };
            
              getMediaAndPlay();
                
                } catch (err) {
                    // setError(err.message);
                    console.error('Error:', err);
                }
            };
    
            fetchData();
    }, []);

    const start_record_button = async () => {
      // console.log("1111")
      if (CanRecord){
        if (!last_audio) {
          if (isRecording) { 
              setIsRecording(false); // 更新状态为结束录音
              setFinishRecording(true);
          } else {
            // 启动录音
            setIsRecording(true);
            set_record_text("Stop Recording");
            
          }
        }
      }
      
       
      };

    const onStop = (audioData) => {
        // console.log('录音数据:', audioData);
        const blob = audioData.blob;
        const url = audioData.url;
        // setFinishRecording(false);

        // for test
        setAudioBlob(blob);
        setAudioUrl(url); // 生成音频的 URL

        set_record_text("Uploading Recording");
        setRecordButtonBackground("#d9d9d9")
        const question_list = JSON.parse(sessionStorage.getItem("part1_questions"))
        if (detail_idx ==question_list.length-1) {
          set_last_audio(true);
        }
        const formData = new FormData();
        formData.append('audio', blob);
        formData.append('question', question_list[detail_idx]);
        formData.append('detail_idx', detail_idx);
        formData.append('last_audio', last_audio);
        // formData.append('username', "pandafanfanfan@gmail.com");
        // formData.append('score', "7");
        formData.append('part_idx', "1");
        formData.append('username', sessionStorage.getItem('email'));
        formData.append('score', sessionStorage.getItem('targetScore'));
        formData.append('question_name', sessionStorage.getItem('question_name'));
        formData.append('question_labels', sessionStorage.getItem('question_labels'));

        fetch('https://koispeak.com/api/utils/upload_audios/', {
          method: 'POST',
          body: formData
        })
        .then(response => response.json()) // 处理返回的 JSON 数据
        .then(responseData => {
          console.log('Upload successful', responseData);
          // 可以根据返回数据执行后续操作
        })
        .catch(error => {
          console.error('Error uploading the recording', error);
          // 可以执行错误处理逻辑
        });
        setNextButtonBackground("#505F98")
        if (last_audio) {
          setNextText("Next Part");
          setNextText("Finish Uploading");
        } else {
          
          set_record_text("Start Recording");
          const new_detail_idx = detail_idx + 1;
          set_detail_idx(new_detail_idx);
          const audio_path = `https://koispeak.com/static/library/audio_output/${sessionStorage.getItem("questionType")}/part1/${JSON.parse(sessionStorage.getItem("part1_question_idx_list"))[new_detail_idx]}.mp3`;
          console.log("audio_path", audio_path)
          questionAudioRef.current.src = audio_path;
        }
      
        
      };

    const showQuestion = () => {
        // console.log("part1", )
        const part1_questions = JSON.parse(sessionStorage.getItem("part1_questions"))
        setQuestionContent(part1_questions[detail_idx]);
    };

    const next_part = () => {
      // console.log("part1", )
      if (last_audio){
        navigate(`/instruction?part_idx=${2}`);
      } else if (FinishRecording) {
        setQuestionContent('Click to show the question');
        setCurrentTime(0);
        playAudio();
        setFinishRecording(false);
        setRecordButtonBackground("#d9d9d9");
        setNextButtonBackground("#d9d9d9");
      }
      
  };

    useEffect(() => {
      if (isRecording) {
        timerRef.current = setInterval(() => {
          setCurrentTime((prevTime) => prevTime + 1);
        }, 1000); // 每秒更新
      } else {
        clearInterval(timerRef.current); // 停止录音时清除定时器
        // setCurrentTime(0);
      }
  
      return () => {
        clearInterval(timerRef.current); // 清理定时器
      };
    }, [isRecording]);
    return (
        <div className={styles.main_container}>
            <audio
                ref={questionAudioRef}
                src="https://koispeak.com/static/audio/check.mp3"  // 替换为你的音频路径
                onEnded={handleAudioEnded}
                />
            <div 
                style={{ display: 'none' }} // 让 div 隐藏，没样式
                onClick={playAudio} // 绑定点击事件
            />
          <div className={styles.rectangle}>
            <span className={styles.koi_speak}>KOI SPEAK</span>
          </div>
          <span className={styles.part_one}>PART ONE</span>
          <span className={styles.interview_and_introduction}>
            INTERVIEW AND INTRODUCTION
          </span>
          <div className={styles.rectangle_1}>
            <div className={styles.ellipse} />
            <div className={styles.rectangle_2} />
          </div>
          <div className={styles.rectangle_3} onClick={showQuestion}>
            <span className={styles.click_to_show}>{QuestionContent}</span>
          </div>
          <div className={styles.flex_row} >
            <AudioReactRecorder
              state={isRecording ? RecordState.START : RecordState.STOP}
              onStop={onStop}
              canvasHeight={96}
              canvasWidth={896}
              backgroundColor="rgb(255, 255, 255)"
              style={{
                width: '896px !important',
                height: '96px !important',
              }}
            />
            <div className={styles.mic} />
            {/* <div className={styles.line} /> */}
          </div>
          <span className={styles.span}>{`${Math.floor(currentTime / 60)}:${String(currentTime % 60).padStart(2, "0")}`}</span>
          <div className={styles.flex_row_cc}>
            <button className={styles.frame} 
            onClick={start_record_button}
            style={{background: RecordButtonBackground}}>
              <span className={styles.start_recording}>{record_text}</span>
              <audio ref={audioRef} src={audioUrl} />
            </button>
            {/* {audioUrl && (
              <a
                href={audioUrl}        // Set the href to the generated audio URL
                download="recording.mp3"  // Set the filename you want for the download
                className="download-link"
              >
                Download Recording
              </a>
            )} */}
            <div className={styles.rectangle_4} onClick={next_part} style={{background: NextButtonBackground}}>
              <span className={styles.next}>{nextText}</span>
              <div className={styles.flex_column}>
                <div className={styles.line_5} />
                <div className={styles.line_6} />
              </div>
            </div>
          </div>
        </div>
      );
}
