import React from 'react';
import styles from './instruction.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


export default function Instruction() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const part_idx = queryParams.get('part_idx'); // 获取 `id` 参数
    const[title, setTitle] = useState("");
    const[firstBold, setFirstBold] = useState("");
    const[secondBold, setSecondBold] = useState("");
    const[thirdBold, setThirdBold] = useState("");
    const[firstContent, setFirstContent] = useState("");
    const[secondContent, setSecondContent] = useState("");
    const[thirdContent, setThirdContent] = useState("");
    const[fourthContent, setFoutrhContent] = useState("");
    useEffect(() => {
        if (part_idx==1) {
            setTitle("PART 1");
            setFirstBold(" around 5 minutes");
            setSecondBold(" 20s to 40s.");
            setThirdBold(" in a quiet environment");
            setFirstContent("In PART 1, the examiner will ask you general questions about yourself. It usually lasts for");
            setSecondContent(". Ideally, each of your answer in this part should last between");
            setThirdContent("To ensure the best results for your test, we recommend taking it");
            setFoutrhContent(". This will improve the quality of your recording, allowing for more accurate scoring.");
        } else if (part_idx==2) {
            setTitle("PART 2");
            setFirstBold(" 1 minute");
            setThirdBold(" up tp 2 minutes.");
            setFirstContent("In PART 2, you will get a task card with a topic. You will have");
            setSecondContent(" to prepare your answer. You can take notes during the preparation time. Make sure you have a piece of white paper and a pen.");
            setThirdContent("After the preparation time, you will speak about this topic for");
        } else if (part_idx==3) {
            setTitle("PART 3");
            setFirstBold(" around 5 minutes.");
            setThirdBold(" around 1 minute.");
            setFirstContent("In PART 3, you will be asked several questions related to the topic from Part 2. This section typically lasts for");
            setThirdContent("You are expected to provide detailed answer to each question. Ideally, each response should last for");
        }     
       
        }, []);

    const start_part = () => {
        navigate(`/part${part_idx}`);
    }
    return (
        <div className={styles.main_container}>
        <div className={styles.rectangle}>
            <span className={styles.koi_speak}>KOI SPEAK</span>
        </div>
        <div className={styles.rectangle_1}>
            <span className={styles.part_instruction}>
            {title} <br />
            INSTRUCTION
            </span>
            <div className={styles.line} />
            <div className={styles.general_questions}>
            <span className={styles.general_questions_2}>
                {firstContent}
            </span>
            <span className={styles.around_5_minutes}>{firstBold}</span>
            {/* <span className={styles.general_questions_3}>. </span> */}
            <span className={styles.each_answer_ideal}>
                {secondContent}
            </span>
            <span className={styles.around_5_minutes_4}>{secondBold}</span>
            </div>
            <div className={styles.best_test_environment}>
            <span className={styles.best_test_recommendation}>
                {thirdContent}
            </span>
            <span className={styles.quiet_environment}>{thirdBold}</span>
            <span className={styles.improve_quality}>
                {fourthContent}
            </span>
            </div>
        </div>
        <div className={styles.rectangle_5} onClick={start_part}>
            <span className={styles.next}>NEXT</span>
            <div className={styles.flex_column}>
            <div className={styles.line_6} />
            <div className={styles.line_7} />
            </div>
        </div>
        </div>
    );
}
