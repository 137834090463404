import React from 'react';
import styles from './part2.module.css';
import { useState, useEffect, useRef} from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { useNavigate } from 'react-router-dom';

export default function Part2() {
    const navigate = useNavigate();
    const [QuestionContent, setQuestionContent] = useState("");
    const [QuestionTitle, setQuestionTitle] = useState([]);
    const [timeLeft, setTimeLeft] = useState(60); // 初始倒计时设为60秒
    const [isRecording, setIsRecording] = useState(false);
    const [NextBackgroup, setNextBackground] = useState("#d9d9d9")
    const [NextText, setNextText] = useState("Next");
    const [width, setWidth] = useState(100);
    const [stage, setStage] = useState(0);
    const [gifDisplay, setGifDisplay] = useState('none')
    const questionAudioRef = useRef(null);
    const [backgroundPic, setBackgroundPic] = useState('url(https://koispeak.com/static/images/pc5/clock.png)');
    const timerRef = useRef(null); 

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const sec = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
    }

    const listToString = (list) => {
        return list.map((item, index) => (
          // 每个项后加一个 <br /> 标签

          `${index > 0 ? '● ' : ''}${item}<br/>`
        )).join('');
      };

    useEffect(() => {
        // console.log("part2_questions", JSON.parse(sessionStorage.getItem("part2_questions")))
        const part2_questions = JSON.parse(sessionStorage.getItem("part2_questions"));
        // console.log("part2_questions", part2_questions)
        setQuestionTitle(part2_questions[0]);
        setQuestionContent(listToString(part2_questions.slice(1)));
        // console.log("QuestionContent", QuestionContent)
        const getMediaAndPlay = async () => {
            try {
              const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
              // 此处假设已经有了audioRef或类似的用于控制音频的ref
              playAudio();
            } catch (error) {
              console.error('Error accessing the microphone:', error);
            }
          };
      
        getMediaAndPlay();
          
    }, []);

    const handleAudioEnded = () => {
        if (stage == 0){
            setTimeLeft(60); // 重新设置时间为60秒
            setStage(1); // 设置为第一阶段倒计时
        }
        if (stage == 1){
          setStage(2); // 设置为第一阶段倒计时
      }
        
    };

    const playAudio = () => {
        questionAudioRef.current.play();
    };

    useEffect(() => {
      if (timeLeft > 0 && stage > 0) {  // 修改这里确保 timeLeft 大于 0
          timerRef.current = setTimeout(() => {
              // 只有 timeLeft 大于 1 时才继续减 1
              if (timeLeft > 1) {
                  setTimeLeft(timeLeft - 1);
              } else {
                  // 当倒计时到 1 时设置为 0，并触发播放音频
                  setTimeLeft(0);
  
                  // 播放提醒音频逻辑
                  if (stage === 1) {
                      questionAudioRef.current.src = "https://koispeak.com/static/audio/remind.mp3";
                      const getMediaAndPlay = async () => {
                          try {
                              const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                              // 此处假设已经有了audioRef或类似的用于控制音频的ref
                              playAudio();
                          } catch (error) {
                              console.error('Error accessing the microphone:', error);
                          }
                      };
                      getMediaAndPlay();
                  }
              }
          }, 1000);
      } else if (stage === 2) {
          // console.log("11111")
          setStage(3); // 切换到第二阶段
          resetTimer(120); // 设置两分钟倒计时
      }
      //  else {
      //     console.log("22222")
      //     setIsRecording(false);
      // }
      return () => clearTimeout(timerRef.current);
  }, [timeLeft, stage]);

    const resetTimer = (newTime) => {
        setWidth(240);
        setNextBackground("#505f98")
        clearTimeout(timerRef.current);
        setTimeLeft(newTime);
        setBackgroundPic("url(https://koispeak.com/static/images/pc5/mic.png)");
        setIsRecording(true);
        setNextText("Finish Recording")
    };

    const next_part = () => {
        clearTimeout(timerRef.current);
        setIsRecording(false);
    };

    const onStop = (audioData) => {
        const blob = audioData.blob;
        // const url = audioData.url;
        setNextBackground("#d9d9d9")
        setGifDisplay('block');
        // for test
        // setAudioBlob(blob);
        // setAudioUrl(url); // 生成音频的 URL

        setNextText("Uploading Record");
        const question_list = JSON.parse(sessionStorage.getItem("part2_questions"))
 
        const formData = new FormData();
        formData.append('audio', blob);
        formData.append('question', question_list);
        formData.append('detail_idx', 0);
        formData.append('last_audio', true);
        // formData.append('username', "pandafanfanfan@gmail.com");
        // formData.append('score', "7");
        formData.append('part_idx', "2");
        formData.append('username', sessionStorage.getItem('email'));
        formData.append('score', sessionStorage.getItem('targetScore'));
        formData.append('question_name', sessionStorage.getItem('question_name'));
        formData.append('question_labels', sessionStorage.getItem('question_labels'));

        fetch('https://koispeak.com/api/utils/upload_audios/', {
          method: 'POST',
          body: formData
        })
        .then(response => response.json()) // 处理返回的 JSON 数据
        .then(responseData => {
            console.log('Upload successful', responseData);
            setNextBackground("#505F98")
            // setNextText("Next Part");
            navigate(`/instruction?part_idx=${3}`);
          // 可以根据返回数据执行后续操作
        })
        .catch(error => {
          console.error('Error uploading the recording', error);
          // 可以执行错误处理逻辑
        });
        
        // navigate(`/instruction?part_idx=${3}`);

      };

  return (
    <div className={styles.main_container}>
        <img className={styles.loading_gif} 
        src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" 
        alt="Loading..." 
        style={{display: gifDisplay}}/>
        <audio
            ref={questionAudioRef}
            src="https://koispeak.com/static/audio/auto_play.mp3"  // 替换为你的音频路径
            onEnded={handleAudioEnded}
            />
        <div 
            style={{ display: 'none' }} // 让 div 隐藏，没样式
            onClick={playAudio} // 绑定点击事件
        />
      <div className={styles.rectangle}>
        <span className={styles.logo_placeholder}>
        KOI SPEAK
        </span>
      </div>
      <span className={styles.part_two}>PART TWO</span>
      <span className={styles.time}>{formatTime(timeLeft)}</span>
      <div className={styles.rectangle_1} style={{background: NextBackgroup,width: `${width}px`}} onClick={next_part}>
        <span className={styles.next}>{NextText}</span>
        {/* <div className={styles.flex_column}>
          <div className={styles.line} />
          <div className={styles.line_2} />
        </div> */}
      </div>
      <span className={styles.describe_activity}>
        {QuestionTitle}
      </span>
      <div className={styles.rectangle_3}>
        <div className={styles.ellipse} />
        <div className={styles.rectangle_4} />
      </div>
      <span 
        className={styles.describe_activity_details}
        dangerouslySetInnerHTML={{ __html: QuestionContent }} 
      />
      <div className={styles.wave_box}>
        <AudioReactRecorder
                    state={isRecording ? RecordState.START : RecordState.STOP}
                    onStop={onStop}
                    canvasHeight={96}
                    canvasWidth={899}
                    backgroundColor="rgb(255, 255, 255)"
                    style={{
                    width: '899px !important',
                    height: '96px !important',
                    }}
                />
        <div className={styles.clock}
        style={{ background: backgroundPic, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}/>
        {/* <div className={styles.line_5} /> */}
        
      </div>
      
    </div>
  );
}
