import React, { memo, useState, useRef, useEffect } from 'react';

const ShowQuestions = memo(({ questions, onQuestionSelect }) => {
    const [heights, setHeights] = useState({});
    const [margins, setMargin] = useState({});
    const [topPositions, setTopPositions] = useState({});
    const [activeIndex, setActiveIndex] = useState(0); 
    const refs = useRef(Object.keys(questions).map(() => React.createRef()));

    useEffect(() => {
        const updateHeights = {};
        let currentTop = 0;
        const newTopPositions = {};
        const updateMargin = {}

        refs.current.forEach((ref, index) => {
            if (ref.current) {
                const computedStyle = window.getComputedStyle(ref.current);
                const lineHeight = parseFloat(computedStyle.lineHeight);
                const actualHeight = ref.current.clientHeight;
                const isLongQuestion = actualHeight > lineHeight;
                updateHeights[index] = isLongQuestion ? '20%' : '12.59%';
                updateMargin[index] = isLongQuestion ? '-5px' : '0px';
                newTopPositions[index] = `${currentTop}%`;
                currentTop += isLongQuestion ? 25 : 18;
            }
        });

        setTopPositions(newTopPositions);
        setHeights(updateHeights);
        setMargin(updateMargin);
    }, [questions]);

    const handleDivClick = (i, question) => {
        onQuestionSelect(i + 1, question);
        setActiveIndex(i);
    };

    return (
        <div className="questions-container">
            {Object.entries(questions).map(([index, question], i) => (
                <div 
                    key={i}
                    className="rectangle"
                    onClick={() => handleDivClick(i, question)} 
                    style={{
                        position: 'absolute',
                        width: '95%',
                        height: heights[i] || '12.59%',
                        top: topPositions[i],
                        background: '#f7f7fa',
                        zIndex: '95',
                        borderRadius: '20px',
                        borderBottom: activeIndex === i ? '3px solid #505f98' : 'none',
                        borderRight: activeIndex === i ? '3px solid #505f98' : 'none'
                    }}
                >
                    <span
                        ref={refs.current[i]}
                        className="question-text"
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            position: 'absolute',
                            marginTop: margins[i],
                            width: '96%',
                            top: '23.53%',
                            left: '2%',
                            color: '#000',
                            fontFamily: 'Nunito, sans-serif',
                            fontSize: '20px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            textAlign: 'left',
                            whiteSpace: 'normal',
                            zIndex: '96'
                        }}
                    >
                        {`${parseInt(index) + 1}. ${question}`}
                    </span>
                </div>
            ))}
        </div>
    );
});

export default ShowQuestions;