import React from 'react';
import styles from './testEquipment.module.css';
import { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
// import RecordRTC from 'recordrtc';


export default function TestEquipment() {
    const navigate = useNavigate();
    const [checkHeadPhone, setCheckHeadPhone] = useState(false);
    const [checkMicPhone, setCheckMicPhone] = useState(false);

    const [opacity, setOpacity] = useState({
        rectangle3: 1,
        rectangle7: 1,
        rectangle8: 1,
        rectangle12: 0.3,
        rectangle_a: 0.3,
        button_frame: 0.3
    });

    // 点击事件处理器
    const handleHeadPhoneClick = () => {
        if (!checkHeadPhone){
            // 更新 rectangle_8 的打钩状态
            setCheckHeadPhone(!checkHeadPhone);
        
            // 更新其他元素的透明度
            setOpacity((prevOpacity) => ({
            rectangle3: 0.3,  // 设置 rectangle_3 和 rectangle_7 的透明度
            rectangle7: 0.3,
            rectangle_a: 1,
            rectangle8: 1,    // 设置 rectangle_8 的透明度
            rectangle12: 1    // 设置 rectangle_12 的透明度
            }));
            if (audioRef.current) {
                audioRef.current.pause();
                }
            clearInterval(intervalRef.current); // 清除定时器
            setIsPlaying(false); // 更新播放状态
        }
    
    };

    const handleMicPhoneClick = () => {
        if (!checkMicPhone && checkHeadPhone){
            // 更新 rectangle_8 的打钩状态
            setCheckMicPhone(!checkMicPhone);
            
            // 更新其他元素的透明度
            setOpacity((prevOpacity) => ({
            rectangle3: 0.3,  // 设置 rectangle_3 和 rectangle_7 的透明度
            rectangle7: 0.3,
            rectangle_a: 0.3,
            rectangle12: 0.3,    // 设置 rectangle_12 的透明度
            button_frame: 1,
            }));
        }
    };

    const startTest = () => {
        if (!checkHeadPhone){
            alert("Please check your headphone!")
        }
        if (!checkMicPhone){
            alert("Please check your micphone!")
        }
        const part_idx = 1;
        navigate(`/instruction?part_idx=${part_idx}`);
    }

    const audioRef = useRef(null);
    const intervalRef = useRef(null);

    // 设置播放状态、当前时间和音频总时长
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [headphoneDuration, setDuration] = useState(11);  // 假设音频时长为 11 秒
    const [ellipsePosition, setEllipsePosition] = useState(196.594);  // 进度条位置

    // 切换播放/暂停
    const togglePlayPause = () => {
        if (!checkHeadPhone) {
            if (isPlaying) {
                audioRef.current.pause();  // 暂停
                } else {
                audioRef.current.play();   // 播放
                }
            setIsPlaying(!isPlaying);  // 切换播放状态  
        } 
    };

    const handleCheckAudioEnd = () => {
        // console.log("1111")
        setIsPlaying(false);
        setEllipsePosition(196.5);
        // setRecordPlayImage('https://koispeak.com/static/images/pc3/6914bee9-aeb2-41ef-b1c8-dc2bf867d9b7.png')
    }

    // 每秒更新播放时间
    useEffect(() => {
        if (isPlaying) {
        intervalRef.current = setInterval(() => {
            if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);

            // 计算 ellipse 的 left 位置
            const progress = (audioRef.current.currentTime / headphoneDuration) * 100;
            const startPosition = 196.594;
            const maxPosition = 758;
            const newPosition = startPosition + (progress / 100 * 1.2) * (maxPosition - startPosition);

            setEllipsePosition(newPosition);
            }
        }, 500); // 每秒更新一次
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current); // 清理定时器
    }, [isPlaying, headphoneDuration]);

    // 格式化时间（例如：00:01 / 00:11）
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const [isRecording, setIsRecording] = useState(false);
    const [recordingInterval, setRecordingInterval] = useState(null);
    const [recordImage, setRecordImage] = useState('https://koispeak.com/static/images/pc3/64096fd1-4513-420c-9ce2-7f9220d82a78.png');
    const [recordPlayImage, setRecordPlayImage] = useState('https://koispeak.com/static/images/pc3/gray_play.png');
    const [hasRecord, setHasRecord] = useState(false);
    const [isPlayingRecord, setIsPlayingRecord] = useState(false);
    const [recordCurrentTime, setRecordCurrentTime] = useState(0);
    const [recordDuration, setRecordDuration] = useState(0);
    const [recordProgress, setRecordProgress] = useState(251);
    const recordAudioRef = useRef(null); // 用于控制播放的音频

    const toggleRecording = () => {
        if (checkHeadPhone && !checkMicPhone && !hasRecord){
            if (isRecording) {
                stopRecording();
            } else {
                startRecording();
            }
        }
      };

    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    let startTime;
 
    
    const startRecording = async () => {
        setIsRecording(true);
        const interval = setInterval(() => {
            setRecordImage(prev =>
                prev === 'https://koispeak.com/static/images/pc3/recording.png'
                    ? 'https://koispeak.com/static/images/pc3/64096fd1-4513-420c-9ce2-7f9220d82a78.png'
                    : 'https://koispeak.com/static/images/pc3/recording.png'
            );
        }, 800);
        setRecordingInterval(interval);

        console.log("Starting recording...");
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: 'audio/webm',
                audioBitsPerSecond: 128000  // 提升音频比特率
            });
            audioChunks.current = [];
            mediaRecorderRef.current.ondataavailable = event => {
                // console.log("Data available...");
                audioChunks.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = () => {
                // console.log("Recording stopped.");
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                const stopTime = Date.now();
                const duration = parseInt((stopTime - startTime) / 1000);
                // console.log("duration", duration)
                // setAudioBlob(audioBlob);
                setRecordDuration(duration);
                const audioUrl = URL.createObjectURL(audioBlob);
                if (recordAudioRef.current) {
                    recordAudioRef.current.src = audioUrl;
                }
                
               
            };
            mediaRecorderRef.current.start();
            startTime = Date.now();
            // console.log("mediaRecorderRef.current", mediaRecorderRef.current)
            // console.log("Recording started...");
        } catch (error) {
            console.error("Error starting recording:", error);
            setIsRecording(false);
            clearInterval(interval);
        }
    };

    const stopRecording = () => {
        // console.log("Stopping recording...");
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            // console.log("mediaRecorder", mediaRecorderRef.current);
        } 
        setIsRecording(false);
        clearInterval(recordingInterval);
        setRecordingInterval(null);
        setHasRecord(true);
        setRecordImage('https://koispeak.com/static/images/pc3/64096fd1-4513-420c-9ce2-7f9220d82a78.png');
        setRecordPlayImage('https://koispeak.com/static/images/pc3/6914bee9-aeb2-41ef-b1c8-dc2bf867d9b7.png')
       
    };

    const togglePlayRecord = () => {
        if (!hasRecord && isRecording) {
            stopRecording();
        }
        if (hasRecord && !checkMicPhone) {
            if (isPlayingRecord) {
                setRecordPlayImage('https://koispeak.com/static/images/pc3/6914bee9-aeb2-41ef-b1c8-dc2bf867d9b7.png')
                recordAudioRef.current.pause();
            } else {
                setRecordPlayImage('https://koispeak.com/static/images/pc3/stop_icon.png')
                recordAudioRef.current.play();
            }
            setIsPlayingRecord(!isPlayingRecord);
        }
        
    };

    const handleAudioTimeUpdate = () => {
        const audio = recordAudioRef.current;
        setRecordCurrentTime(audio.currentTime);
        const progress = (audio.currentTime / recordDuration) * 100;
        const startPosition = 251;
        const maxPosition = 690;
        const newPosition = startPosition + (progress / 100) * (maxPosition - startPosition);
        setRecordProgress(newPosition);
      };
    const handleAudioEnd = () => {
        setIsPlaying(false);
        setRecordProgress(251);
        setRecordPlayImage('https://koispeak.com/static/images/pc3/6914bee9-aeb2-41ef-b1c8-dc2bf867d9b7.png')
      };
    
    return (
        <div className={styles.main_container}>
        <div className={styles.rectangle}>
            <span className={styles.koi_speak}>KOI SPEAK</span>
            <span className={styles.koi_speak_1}>KOI SPEAK</span>
            <span className={styles.koi_speak_2}>KOI SPEAK</span>
        </div>
        <span className={styles.ielts_mock_test}>IELTS MOCK TEST</span>
        <button className={styles.button_frame} onClick={startTest} style={{ opacity: opacity.button_frame }}>
            <span className={styles.enter_interview_room}>Enter Interview Room</span>
        </button>
        <div className={styles.rectangle_3} style={{ opacity: opacity.rectangle3 }}>
            <div className={styles.flex_row_e}>
            <div className={styles.headphone_icon} />
            <span className={styles.headphone_check}>HEADPHONE CHECK</span>
            </div>
            <div className={styles.flex_row_e_4}>
            <div className={styles.play_button} />
            <span className={styles.sound_quality_test}>
                To check the sound quality and volume of your headphone, click the
                play button&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. <br />
                Make sure you can hear the testing audio clearly. After finishing
                test, tick the “HEADPHONE” box.
            </span>
            </div>
            <div className={styles.rectangle_5} >
                <div className={`${styles.play_button_6} ${isPlaying ? styles.playing : ''}`} onClick={togglePlayPause} />
                <span className={styles.time_display}>{formatTime(currentTime)} / {formatTime(headphoneDuration)}</span>
                <div className={styles.ellipse} style={{ left: `${ellipsePosition}px` }}/>
                <div className={styles.line} />
                <audio
                    ref={audioRef}
                    src="https://koispeak.com/static/audio/check.mp3"  // 替换为你的音频路径
                    onEnded={handleCheckAudioEnd}
                    // controls
                />
            </div>
        </div>
        <div className={styles.rectangle_7} onClick={handleHeadPhoneClick} style={{ opacity: opacity.rectangle7 }}>
            <span className={styles.headphone}>HEADPHONE</span>
            <div 
                className={`${styles.rectangle_8} ${checkHeadPhone ? styles.checked : ''}`} 
                style={{ opacity: opacity.rectangle8 }}
            />
            <div className={styles.line_9} />
        </div>
        <div className={styles.rectangle_a} style={{ opacity: opacity.rectangle_a }}>
            <div className={styles.rectangle_b}>
                <div className={styles.play_button_c} 
                onClick={togglePlayRecord}
                style={{ backgroundImage: `url(${recordPlayImage})` }}/>
                <div className={styles.record_button} 
                    onClick={toggleRecording}
                    style={{ backgroundImage: `url(${recordImage})` }}/>
                <div className={styles.ellipse_d} style={{ left: `${recordProgress}px` }}/>
                <span className={styles.time_display_e}> {formatTime(recordCurrentTime)} / {formatTime(recordDuration)}</span>
                <div className={styles.line_f} />
                <audio
                ref={recordAudioRef}
                onTimeUpdate={handleAudioTimeUpdate}
                onEnded={handleAudioEnd}
                />

            </div>
            <div className={styles.mic_icon} />
            <span className={styles.microphone_check}>MICROPHONE CHECK</span>
            <span className={styles.record_play_buttons}>
            To record your own speech, click the record button &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.<br />
            To listen to the recording, click the play button &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. You can repeat the
            process multiple times if needed.
            <br />
            Make sure your microphone works well before starting the test. When
            you finish tick the “MICROPHONE” box.
            </span>
            <div className={styles.record_button_10} />
            <div className={styles.play_button_11} />
        </div>
        <div className={styles.rectangle_12} onClick={handleMicPhoneClick} style={{ opacity: opacity.rectangle12 }}>
            <span className={styles.microphone}>MICROPHONE</span>
            <div 
                className={`${styles.rectangle_13} ${checkMicPhone ? styles.checked : ''}`} 
               
            />
            <div className={styles.line_14} />
        </div>
        </div>
  );
}
