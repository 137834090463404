import React from "react";
import styles from './Report.module.css';
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Part1Questions from './part1_origin_question';
import Part3Questions from './part3_origin_question';

export default function Report() {
  const [language, setLanguage] = useState("English");
  const [part1Info, setPart1Info] = useState(null);
  const [part2Info, setPart2Info] = useState(null);
  const [part3Info, setPart3Info] = useState(null);
  
  // const [hasPart2, setHasPart2] = useState(false);
  // const [hasPart3, setHasPart3] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get('user_email');
  const testIdx = queryParams.get('test_idx');

  useEffect(() => {
    if (!userEmail || !testIdx) {
        console.error("Missing user_email or test_idx in the URL");
        return;
    }

    fetch('https://koispeak.com/api/utils/get_output/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_email: userEmail, test_idx: testIdx })
    })
    .then(response => response.json())
    .then(data => {
        // 检查语言并更新
        if (language !== "English") {
            setLanguage("中文");
        }
        // console.log("data", data[language]['得分'])
        if (data[language]['得分'] != undefined){
          setTotalScore(data[language]['得分'])
        }

        if (data[language]['评价'] != undefined){
          setOverall(data[language]['评价'])
        }
        // 设置各部分信息
        data[language]['part1']["part_idx"] = 0;
        setPart1Info(data[language]['part1']);
        // setHasPart2(data[language]['part2'] !== undefined);
        if (data[language]['part2'] !== undefined) {
          data[language]['part1']["part_idx"] = 1;
            setPart2Info(data[language]['part2']);
        }
        // console.log("data[language]['part3']", data[language]['part3'] !== undefined)
        // setHasPart3();
        
        if (data[language]['part3'] !== undefined) {
          // console.log("hasPart3", data[language]['part3'])
          data[language]['part3']["part_idx"] = 2;
          setPart3Info(data[language]['part3']);
        }
        
    });
}, []);

  const containerRef = useRef(null);
  const TopBarRef = useRef(null);
  const OverallRectangleRef = useRef(null);
  const [totalScore, setTotalScore] = useState("N/A");
  const [Overall, setOverall] = useState("总评正在生成，请稍等")
  useEffect(() => {
    if (containerRef.current) {
        // 动态调整容器高度，例如基于内容高度
        containerRef.current.style.height = `${containerRef.current.scrollHeight+20}px`;
        const height_diff = containerRef.current.scrollHeight-194;
        TopBarRef.current.style.marginTop = `${height_diff}px`;
        
    }
  }, [Overall]); 

  const [PartIdx, setPartIdx] = useState(0);
  const handleTabClick = (idx) => {
    setPartIdx(idx);
    console.log("partIdx", PartIdx);
    // console.log("part3Info", part3Info);
  };

  return (
    <div className={styles.main_container}>
      <div ref={OverallRectangleRef} className={styles.rectangle}>
        <div className={styles.flex_row_a}>
          <div className={styles.ellipse} />
          <span className={styles.overall}>OVERALL</span>
          <span className={styles.dot_eight_five}>{totalScore}</span>
          <div ref={containerRef} className={styles.rectangle_1}>
            <span className={styles.operational_command}>{Overall}
            </span>
          </div>
        </div>
        <div ref={TopBarRef} className={styles.flex_row_bff}>
          <div className={styles.part_tab}
          onClick={() => handleTabClick(0)}
          style={{
            borderBottom:
            PartIdx === 0 ? "10px solid #505f98" : "10px solid white",
          }}
          >
            <div className={styles.part_icon} />
            <div className={styles.part_text}>
              <span className={styles.part_one}
              style={{
                fontWeight:
                PartIdx === 0 ? "800" : "500",
              }}
              >Part 1</span>
              <span className={styles.introduction_interview}
              style={{
                fontWeight:
                PartIdx === 0 ? "700" : "400",
              }}>
                Introduction and interview
              </span>
            </div>
          </div>
          <div className={styles.part_tab_2}
            onClick={() => handleTabClick(1)}
            style={{
              borderBottom:
              PartIdx === 1 ? "10px solid #505f98" : "10px solid white",
            }}>
            <div className={styles.part_icon_3} />
            <div className={styles.flex_column_c}>
              <span className={styles.part_two}
              style={{
                fontWeight:
                PartIdx === 1 ? "800" : "500",
              }}>Part 2</span>
              <span className={styles.topic}
              style={{
                fontWeight:
                PartIdx === 1 ? "700" : "400",
              }}>Topic</span>
            </div>
          </div>
          <div className={styles.part_tab_4}
            onClick={() => handleTabClick(2)}
            style={{
              borderBottom:
              PartIdx === 2 ? "10px solid #505f98" : "10px solid white",
            }}>
            <div className={styles.part_icon_5} />
            <div className={styles.flex_column}>
              <span className={styles.part_three}
              style={{
                fontWeight:
                PartIdx === 2 ? "800" : "500",
              }}>Part 3</span>
              <span className={styles.discussion}
              style={{
                fontWeight:
                PartIdx === 2 ? "700" : "400",
              }}>Discussion</span>
            </div>
          </div>
        </div>
      </div>
      {
        (PartIdx === 0 && part1Info) 
        ? <Part1Questions myProp={part1Info} />
        // : (PartIdx === 1 && part2Info) 
        // ? <PartQuestions myProp={part2Info} />
        : (PartIdx === 2 && part3Info) 
        ? <Part3Questions myProp={part3Info} />
        : <div>Loading...</div>
      }
      {/* <div className={styles.flex_row_e}>
        <div className={styles.rectangle_16}>
          <div className={styles.vocab_icon} />
          <span className={styles.vocabulary}>Vocabulary</span>
        </div>
        <div className={styles.rectangle_17}>
          <div className={styles.grammar_icon} />
          <span className={styles.grammar}>Grammar</span>
        </div>
        <div className={styles.rectangle_18}>
          <div className={styles.fluency_icon} />
          <button className={styles.fluency} />
        </div>
        <div className={styles.rectangle_19}>
          <div className={styles.pronounciation_icon} />
          <span className={styles.pronunciation}>Pronunciation</span>
        </div>
        <span className={styles.detailed_breakdown}>Detailed Breakdown</span>
        <div className={styles.rectangle_1a}>
          <div className={styles.evaluation_icon} />
          <span className={styles.evaluations}>Evaluations</span>
        </div>
        <div className={styles.rectangle_1b}>
          <div className={styles.rectangle_1c}>
            <span className={styles.vocabulary_distribution}>
              Vocabulary Distribution
            </span>
            <div className={styles.line_1d} />
            <span className={styles.advanced_vocabulary}>
              Advanced vocabulary can add color and precision to your speech.
              However, balance it with simplicity to keep your delivery clear
              and natural.{" "}
            </span>
            <div className={styles.flex_row_cb}>
              <span className={styles.your_vocabulary_distribution}>
                Your vocabulary distribution
              </span>
              <div className={styles.typical_band_vocabulary_distribution}>
                <span className={styles.typical}>Typical </span>
                <span className={styles.band_8}>Band 8</span>
                <span className={styles.vocabulary_distribution_1e}>
                  {" "}
                  vocabulary distribution
                </span>
              </div>
            </div>
            <div className={styles.flex_row}>
              <div className={styles.ellipse_1f} />
              <div className={styles.rectangle_20}>
                <div className={styles.rectangle_21} />
              </div>
              <div className={styles.ellipse_22} />
              <div className={styles.rectangle_23}>
                <div className={styles.rectangle_24} />
              </div>
              <span className={styles.c2}>C2</span>
              <span className={styles.c2_25}>C2</span>
            </div>
            <div className={styles.flex_row_f}>
              <div className={styles.ellipse_26} />
              <div className={styles.rectangle_27}>
                <div className={styles.rectangle_28} />
              </div>
              <div className={styles.ellipse_29} />
              <div className={styles.rectangle_2a}>
                <div className={styles.rectangle_2b} />
              </div>
              <span className={styles.c1}>C1</span>
              <span className={styles.c1_2c}>C1</span>
            </div>
            <div className={styles.flex_row_cd}>
              <div className={styles.ellipse_2d} />
              <div className={styles.rectangle_2e}>
                <div className={styles.rectangle_2f} />
              </div>
              <div className={styles.ellipse_30} />
              <div className={styles.rectangle_31}>
                <div className={styles.rectangle_32} />
              </div>
              <span className={styles.b2}>B2</span>
              <span className={styles.b2_33}>B2</span>
            </div>
            <div className={styles.flex_row_d}>
              <div className={styles.ellipse_34} />
              <div className={styles.rectangle_35}>
                <div className={styles.rectangle_36} />
              </div>
              <div className={styles.ellipse_37} />
              <div className={styles.rectangle_38}>
                <div className={styles.rectangle_39} />
              </div>
              <span className={styles.b}>B1</span>
              <span className={styles.b_3a}>B1</span>
            </div>
            <div className={styles.flex_row_b}>
              <div className={styles.ellipse_3b} />
              <div className={styles.rectangle_3c}>
                <div className={styles.rectangle_3d} />
              </div>
              <div className={styles.ellipse_3e} />
              <div className={styles.rectangle_3f}>
                <div className={styles.rectangle_40} />
              </div>
              <span className={styles.a2}>A2</span>
              <span className={styles.a2_41}>A2</span>
            </div>
            <div className={styles.flex_row_ac}>
              <div className={styles.ellipse_42} />
              <div className={styles.rectangle_43}>
                <div className={styles.rectangle_44} />
              </div>
              <div className={styles.ellipse_45} />
              <div className={styles.rectangle_46}>
                <div className={styles.rectangle_47} />
              </div>
              <span className={styles.a1}>A1</span>
              <span className={styles.a1_48}>A1</span>
            </div>
            <div className={styles.dont_get_too_caught_up}>
              <span className={styles.dont_get_too_caught_up_49}>
                Don’t get too caught up in complex words—
              </span>
              <span className={styles.accuracy_and_correctness_are_key}>
                accuracy and correctness are key
              </span>
              <span className={styles.dot}>.</span>
            </div>
          </div>
          <div className={styles.rectangle_4a}>
            <span className={styles.your_top_errors}>Your top errors</span>
            <div className={styles.line_4b} />
            <div className={styles.rectangle_4c}>
              <div className={styles.boring_in_question}>
                <span className={styles.quote}>“</span>
                <span className={styles.boring}>Boring</span>
                <span className={styles.quote_4d}>” in </span>
                <span className={styles.question_1}>Question 1</span>
              </div>
              <div className={styles.sentence}>
                <span className={styles.i_was_so}>I was so</span>
                <span className={styles.space}> </span>
                <span className={styles.boring_4e}>boring</span>
                <span className={styles.i_was_so_4f}> </span>
                <span className={styles.bored}>bored</span>
                <span className={styles.space_uring_the_movie}>
                  {" "}
                  uring the movie."
                </span>
              </div>
              <div className={styles.explanation}>
                <span className={styles.explanation_50}>Explanation:</span>
                <span className={styles.explan_detail}>
                  {" "}
                  "Boring" describes something that causes boredom, while
                  "bored" describes the feeling of being uninterested.
                  <br />
                </span>
              </div>
            </div>
            <div className={styles.rectangle_51}>
              <div className={styles.sympathetic_in_question}>
                <span className={styles.quote_52}>“</span>
                <span className={styles.sympathetic}>Sympathetic</span>
                <span className={styles.quote_53}>” in </span>
                <span className={styles.question_54}>Question 2</span>
              </div>
              <div className={styles.empathetic_sympathetic}>
                <span className={styles.empathetic_sympathetic_55}>I am very </span>
                <span className={styles.sympathetic_56}>sympathetic</span>
                <span className={styles.empathetic_sympathetic_57}> </span>
                <span className={styles.empathetic}>empathetic</span>
                <span className={styles.empathetic_sympathetic_58}>
                  {" "}
                  when people around me are sick or unhappy about their
                  lives..."
                </span>
              </div>
              <div className={styles.explanation_59}>
                <span className={styles.explanation_5a}>Explanation:</span>
                <span className={styles.boring_bored}>
                  {" "}
                  "Boring" describes something that causes boredom, while
                  "bored" describes the feeling of being uninterested.
                  <br />
                </span>
              </div>
            </div>
            <div className={styles.rectangle_5b}>
              <div className={styles.boring_5c}>
                <span className={styles.quote_5d}>“</span>
                <span className={styles.boring_5e}>Boring</span>
                <span className={styles.quote_5f}>” in </span>
                <span className={styles.question_60}>Question 1</span>
              </div>
              <div className={styles.boring_bored_61}>
                <span className={styles.quote_62}>I was so</span>
                <span className={styles.empty}> </span>
                <span className={styles.boring_63}>boring</span>
                <span className={styles.quote_64}> </span>
                <span className={styles.bored_65}>bored</span>
                <span className={styles.during_movie}> uring the movie.</span>
              </div>
              <div className={styles.explanation_66}>
                <span className={styles.explanation_67}>Explanation:</span>
                <span className={styles.boring_bored_68}>
                  {" "}
                  "Boring" describes something that causes boredom, while
                  "bored" describes the feeling of being uninterested.
                  <br />
                </span>
              </div>
            </div>
            <div className={styles.rectangle_69}>
              <div className={styles.sympathetic_6a}>
                <span className={styles.quote_6b}>“</span>
                <span className={styles.sympathetic_6c}>Sympathetic</span>
                <span className={styles.quote_6d}>” in </span>
                <span className={styles.question_6e}>Question 2</span>
              </div>
              <div className={styles.empathetic_sympathetic_6f}>
                <span className={styles.empathetic_sympathetic_70}>I am very </span>
                <span className={styles.sympathetic_71}>sympathetic</span>
                <span className={styles.empathetic_sympathetic_72}> </span>
                <span className={styles.empathetic_73}>empathetic</span>
                <span className={styles.empathetic_sympathetic_74}>
                  {" "}
                  when people around me are sick or unhappy about their
                  lives..."
                </span>
              </div>
              <div className={styles.explanation_75}>
                <span className={styles.explanation_76}>Explanation:</span>
                <span className={styles.boring_bored_77}>
                  {" "}
                  "Boring" describes something that causes boredom, while
                  "bored" describes the feeling of being uninterested.
                  <br />
                </span>
              </div>
            </div>
            <div className={styles.rectangle_78}>
              <div className={styles.boring_79}>
                <span className={styles.quote_7a}>“</span>
                <span className={styles.boring_7b}>Boring</span>
                <span className={styles.quote_7c}>” in </span>
                <span className={styles.question_7d}>Question 1</span>
              </div>
              <div className={styles.boring_bored_7e}>
                <span className={styles.quote_7f}>I was so</span>
                <span className={styles.empty_80}> </span>
                <span className={styles.boring_81}>boring</span>
                <span className={styles.quote_82}> </span>
                <span className={styles.bored_83}>bored</span>
                <span className={styles.during_movie_84}> uring the movie.</span>
              </div>
              <div className={styles.explanation_85}>
                <span className={styles.explanation_86}>Explanation:</span>
                <span className={styles.explanation_87}>
                  {" "}
                  "Boring" describes something that causes boredom, while
                  "bored" describes the feeling of being uninterested.
                  <br />
                </span>
              </div>
            </div>
          </div>
          <div className={styles.rectangle_88}>
            <span className={styles.lexical_resources}>Lexical Resources</span>
            <div className={styles.line_89} />
            <span className={styles.rectangle_8a}>
              A strong vocabulary enables clear, nuanced communication and
              boosts credibility. It’s essential for adapting to different
              contexts and excelling in language tests.
            </span>
            <div className={styles.rectangle_8b}>
              <div className={styles.important_crucial_significant}>
                <span className={styles.important}>Important / </span>
                <span className={styles.crucial_significant_pivotal}>
                  Crucial / Significant / Pivotal / Essential
                </span>
              </div>
              <div className={styles.rectangle_8c}>
                <div className={styles.crucial}>
                  <span className={styles.crucial_8d}>Crucial: </span>
                  <span className={styles.crucial_8e}>
                    Implies that something is essential for the success or
                    failure of a situation. It often conveys a sense of urgency
                    or necessity.
                    <br />
                  </span>
                  <span className={styles.crucial_8f}>Example: </span>
                  <span className={styles.significant}>
                    "It's crucial to arrive on time for the meeting."
                  </span>
                </div>
              </div>
              <div className={styles.significant_90}>
                <div className={styles.significant_91}>
                  <span className={styles.significant_92}>Significant: </span>
                  <span className={styles.significant_93}>
                    Refers to something that has a noticeable or important
                    impact, often in a broader context.
                    <br />
                  </span>
                  <span className={styles.significant_94}>Example:</span>
                  <span className={styles.significant_95}> </span>
                  <span className={styles.significant_96}>
                    "The discovery was significant for the field of science."
                  </span>
                </div>
              </div>
              <div className={styles.rectangle_97}>
                <div className={styles.significant_98}>
                  <span className={styles.significant_99}>Significant: </span>
                  <span className={styles.significant_9a}>
                    Refers to something that has a noticeable or important
                    impact, often in a broader context.
                    <br />
                  </span>
                  <span className={styles.significant_9b}>Example:</span>
                  <span className={styles.significant_9c}> </span>
                  <span className={styles.significant_9d}>
                    "The discovery was significant for the field of science."
                  </span>
                </div>
              </div>
              <div className={styles.rectangle_9e}>
                <div className={styles.significant_9f}>
                  <span className={styles.significant_a0}>Significant: </span>
                  <span className={styles.significant_a1}>
                    Refers to something that has a noticeable or important
                    impact, often in a broader context.
                    <br />
                  </span>
                  <span className={styles.significant_a2}>Example:</span>
                  <span className={styles.significant_a3}> </span>
                  <span className={styles.significant_a4}>
                    "The discovery was significant for the field of science."
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frame}>
            <input className={styles.frame_input} />
            <span className={styles.rectangle_a5}>
              小分的overview写在这里
              <br />
              <br />
              大概这么几行
              <br />
              带衣服也可以
            </span>
          </div>
          <div className={styles.rectangle_a6}>
            <span className={styles.rectangle_a7}>8.5</span>
          </div>
        </div>
        <div className={styles.rectangle_a8}>
          <div className={styles.rectangle_a9}>
            <div className={styles.suggestion_icon} />
            <span className={styles.suggestions}>Suggestions</span>
          </div>
        </div>
        <div className={styles.rectangle_aa}>
          <div className={styles.icon_ai} />
          <div className={styles.flex_column_d}>
            <span className={styles.improvements}>Improvements</span>
            <span className={styles.for_part_2_3}>For Part 2 & 3</span>
          </div>
        </div>
      </div> */}
    </div>
  );
}
