import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Login from './Login/login';
import Register from './Register/register';
import Forget from './Forget/forget';
import MainPage from './MainPage/mainpage';
import MockTest from './MockTest/mocktest';
import TestHistory from './TestHistory/testhistory';
import SelfAccount from './SelfAccount/selfaccount';
import Payment from './Payment/payment';
import Reset from './Reset/reset';
import UserContract from './User_contract/user_contract';
import UserPrivacy from './User_privacy/user_privacy';
import AboutUs from './AboutUs/about_us';
import TargetScore from './RecordPages/target_score'
import TestEquipment from './RecordPages/test_equipment'
import Instruction from './RecordPages/instruction'
import Part1 from './RecordPages/part1'
import Part2 from './RecordPages/part2'
import Part3 from './RecordPages/part3'
import Report from './ReportPages/report'
import TextExpander from './test/test'

export default function App() {
  return (
    // <Router basename="/react">
    <Router>
      <Routes> 
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/mainpage" element={<MainPage />} />
          <Route path="/mocktest" element={<MockTest />} />
          <Route path="/testhistory" element={<TestHistory />} />
          <Route path="/selfaccount" element={<SelfAccount />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/user_contract" element={<UserContract />} />
          <Route path="/user_privacy" element={<UserPrivacy />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/target_score" element={<TargetScore />} />
          <Route path="/test_equipment" element={<TestEquipment />} />
          <Route path="/instruction" element={<Instruction />} />
          <Route path="/part1" element={<Part1 />} />
          <Route path="/part2" element={<Part2 />} />
          <Route path="/part3" element={<Part3 />} />
          <Route path="/report" element={<Report />} />
          <Route path="/test" element={<TextExpander />} />
      </Routes>
    </Router>
  );
}
