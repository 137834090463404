import React, { useState, useEffect } from 'react';
import styles from './MainPage.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNavigation } from '../utils/utils'; 
import axiosInstance from '../axiosInstance/logout';

export default function MainPage() {
  const navigate = useNavigate();
  const { direct_account, _, direct_mock, direct_history, direct_payment, direct_contract, direct_privacy, direct_about, direct_example } = useNavigation();
  const [isVisible, setIsVisible] = useState(false);
  const [money, setMoney] = useState();
  const [helpVisible, setHelpVisible] = useState(false);

  const help_close = () => {
    setHelpVisible(false);
  }

  const direct_help = () => {
    if (helpVisible) {
      setHelpVisible(false);
    }else {
      setHelpVisible(true);
    }
    
  }

  const think_again = () => {
    setIsVisible(false);
  }

  

  const start_test = () => {
    let user_money = parseInt(sessionStorage.getItem('money') || '0'); 

    if (user_money < 20) {
      alert("您的积分不足,请充值");
    } else {
      user_money = user_money - 20;
      sessionStorage.setItem("money", user_money);
      setMoney(user_money);

      // 使用 axiosInstance 替换 fetch
      axiosInstance.put('https://koispeak.com/api/user_profiles/profile/', {
        money: user_money
      })
      .then(response => {
        // 如果请求成功
        const user_email = localStorage.getItem("user_email");
        const encodedEmail = encodeURIComponent(user_email);
        const url = `/target_score?email=${encodedEmail}`;
        navigate(url);
        // const new_window = window.open(url, '_blank');

        // if (!new_window || new_window.closed) {
        //   // 如果新窗口未打开，则恢复积分并提示用户
        //   user_money = user_money + 20;
        //   sessionStorage.setItem("money", user_money);
        //   setMoney(user_money);
          
        //   if (window.confirm("请允许浏览器进行跳转")) {
        //     window.open(url, '_blank');
        //   }
        // }
      })
      .catch(error => {
        // 如果发生错误
        console.error('Error updating money:', error);
        alert('Error: ' + error.message);
      });
    }
  };

  // const navigate = useNavigate(); // 创建navigate函数实例
  // const direct_mock = () => {
  //   navigate('/mocktest');
  // }
  // const direct_payment = () => {
  //   navigate('/payment');
  // }
  const direct_practice = () => {
    // alert("口语练习暂时为开放!");
    setIsVisible(true);
    
  }
  // const direct_history = () => {
  //   navigate('/testhistory');
  // }
  // const direct_account = () => {
  //   navigate('/selfaccount');
  // }
  const missing_alert = () => {
    alert("功能尚未开放");
  }
  const [streak_count, setStreakCount] = useState(0);
  const [test_count, settestCount] = useState(0);
  const [percentage_count, setpercentageCount] = useState(0);
  const [total_min, setTotalMin] = useState(0);

  useEffect(() => {
    
    const fetchUserProfile = async () => {
        try {
            // console.log(accessToken)
            axiosInstance.get('https://koispeak.com/api/user_profiles/profile/', {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('access')}`, // 确保从 localStorage 中获取 access token
              },
          })
          .then(response => {
            setStreakCount(response.data.streak_count);
            settestCount(response.data.test_count);
            setpercentageCount(response.data.percentage_count);
            setTotalMin(response.data.total_min);
            sessionStorage.setItem("last_test", response.data.last_test)
            sessionStorage.setItem("money", response.data.money)
            // console.log("moneymoney",  response.data.money)
            sessionStorage.setItem("history_count", response.data.history_count)
            sessionStorage.setItem("target_score", response.data.target_score)
            sessionStorage.setItem("highest_score", response.data.highest_score)
            sessionStorage.setItem("test_history", JSON.stringify(response.data.test_history))
            setMoney(sessionStorage.getItem("money"));
            // console.log("user_email", localStorage.getItem("user_email"))
              // 处理响应
          })
          .catch(error => {
              console.error("There was an error fetching the user profile!", error);
          });
        } catch (err) {
            console.log(err);
        }
    };
    fetchUserProfile();
  }, []);
    

    return (
        <div className={styles.main_container}>
      <div className={styles.frame}>
        <div className={styles.about_us} onClick={direct_about}>
          <span className={styles.about_us_1}>关于我们</span>
        </div>
        <div className={styles.about_us_2} onClick={direct_contract}>
          <span className={styles.about_us_terms}>条款</span>
        </div>
        <div className={styles.about_us_privacy} onClick={direct_privacy}>
          <span className={styles.about_us_help}>隐私</span>
        </div>
        <div className={styles.about_us_3} onClick={direct_help}>
          <span className={styles.about_us_4}>帮助</span>
        </div>
      </div>
      <div className={styles.flex_row_aaf}>
        <div className={styles.logo_tentative}></div>
        <div className={styles.summary_column}>
          <div className={styles.image}></div>
          <span className={styles.study_day}>今天也是努力学习的一天！</span>
          <div className={styles.image_5}></div>
          <div className={styles.image_6}></div>
          <div className={styles.frame_7}>
            <div className={styles.stats_banner}>
              <div className={styles.frame_8}>
                <div className={styles.frame_9}><span className={styles.number}>{streak_count}</span></div>
                <div className={styles.frame_a}>
                <span
                  className={`${styles.text_tian} ${
                    streak_count > 10 ? styles.textTianOffset : styles.textTianNoOffset
                  }`}
                >
                  天
                </span>
                  </div>
              </div>
              <div className={styles.frame_b}>
                <span className={styles.text_lian_xu_da_ka}>连续打卡</span>
              </div>
            </div>
            <div className={styles.statistic_banner}>
              <div className={styles.frame_c}>
                <div className={styles.frame_d}><span className={styles.number_e}>{test_count}</span></div>
              </div>
              <div className={styles.frame_f}>
                <span className={styles.text_lian_xu_da_ka_10}>模考总计</span>
              </div>
            </div>
            <div className={styles.statistic_banner_11}>
              <div className={styles.frame_12}>
                <div className={styles.frame_13}><span className={styles.number_14}>{percentage_count}</span></div>
                <div className={styles.frame_15}>
                  <span className={styles.text_tian_percentage}>%</span>
                </div>
              </div>
              <div className={styles.frame_16}>
                <span className={styles.text_zhen_ti_ku_yi_shua}>真题库已刷</span>
              </div>
            </div>
            <div className={styles.statistic_banner_17}>
              <div className={styles.frame_18}>
                <div className={styles.frame_19}><span className={styles.span}>{total_min}</span></div>
                <div className={styles.frame_1a}><span className={styles.span_1b}>分钟</span></div>
              </div>
              <div className={styles.frame_1c}>
                <span className={styles.span_1d}>口语练习总时长</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.flex_row_efd}>
        <div className={styles.sidebar}>
          <div className={styles.frame_1e}>
            <button className={styles.button}>
              <div className={styles.home}><div className={styles.icon}></div></div>
              <span className={styles.my_home}>我的主页</span>
            </button>
            <div className={styles.sidebar_my_home} onClick={direct_mock}>
              <div className={styles.mock_exam}>
                <div className={styles.trophy}><div className={styles.icon_1f}></div></div>
              </div>
              <span className={styles.my_home_20}>模拟考试</span>
            </div>
            <div className={styles.sidebar_my_home_21} onClick={direct_example}>
              <div className={styles.oral_practice}></div>
              <span className={styles.my_home_22}>查看样例</span>
            </div>
            <div className={styles.sidebar_homepage} onClick={direct_history}>
              <div className={styles.mock_exam_record}></div>
              <span className={styles.homepage_history}>模考历史</span>
            </div>
          </div>
          <div className={styles.divider}><div className={styles.line}></div></div>
          <div className={styles.sidebar_homepage_23} onClick={direct_account}>
            <div className={styles.my_account}></div>
            <span className={styles.homepage_account}>我的账户</span>
          </div>
          {/* <div className={styles.sidebar_homepage_24}><div className={styles.help}></div></div> */}
        </div>
        <div className={styles.practice_ready}>
          <div className={styles.frame_25}>
            <div className={styles.divider_26}><div className={styles.line_27}></div></div>
            <span className={styles.start_practice}>准备好开始今天的练习了吗？</span>
            <div className={styles.divider_28}><div className={styles.line_29}></div></div>
          </div>
          <div className={styles.flex_row_ba}>
            <div className={styles.frame_2a} onClick={direct_practice}>
              <span className={styles.mock_exam_2b}>仿真模考</span>
            </div>
            <div className={styles.simulation_exam} onClick={missing_alert}>
              <span className={styles.oral_practice_2c}>口语练习(暂未开放)</span>
            </div>
            {/* <div className={styles.rectangle}>
              <div className={styles.text_icon}>
                <span className={styles.report_voucher}>报告兑换券：10</span>
              </div>
            </div> */}
            {/* <div className={styles.rectangle_2d}></div> */}
          </div>
          <div className={styles.flex_row}>
            <div className={styles.frame_2e}>
              <div className={styles.text_icon_2f}>
                <div className={styles.done_ring_round}></div>
                <span className={styles.random_exam}>真题题库随机抽题，仿真模考体验</span>
              </div>
              <div className={styles.text_icon_30}>
                <div className={styles.done_ring_round_31}></div>
                <span className={styles.ai_scoring}>AI打分，详细评分报告助你提升</span>
              </div>
            </div>
            <div className={styles.frame_32}>
              <div className={styles.text_icon_33}>
                <div className={styles.done_ring_round_34}></div>
                <span className={styles.massive_question_bank}>海量真题库，主题任选</span>
              </div>
              <div className={styles.text_icon_35}>
                <div className={styles.done_ring_round_36}></div>
                <span className={styles.oral_practice_37}>口语随时练，保持好语感</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {helpVisible && (
        <div className={styles.help_main_container}>
          <div className={styles.help_close} onClick={help_close} />
          <span className={styles.help_add_wechat}>添加客服微信</span>
          <span className={styles.help_get_materials}>领取海量备考资料助你备考顺利</span>
          <div className={styles.help_image} />
        </div>
      )}
      
      {isVisible && (
      <div className={styles.jump_main_container}>
        <div className={styles.jump_frame}>
          <span className={styles.jump_ready_to_go}>READY TO GO？</span>
        </div>
        <div className={styles.jump_line} />
        <span className={styles.jump_text_2}>
        每次模拟考试AI评分会花费20积分。如在考试途中退出，积分将无法退回。
          <br />
          为了提供更为准确的评估，我们推荐在安静且网络稳定的环境中进行测试。
        </span>
        <button className={styles.jump_frame_button}>
          <div className={styles.jump_frame_div}>
            <div className={styles.jump_my_points}>
              <span className={styles.jump_my_points_1}>我的积分：</span>
              <span className={styles.jump_points}>{money}</span>
            </div>
          </div>
          <div className={styles.jump_frame_2}>
            <div className={styles.jump_frame_3} onClick={direct_payment}>
              <span className={styles.jump_purchase_score}>购买积分</span>
            </div>
          </div>
        </button>
        <div className={styles.jump_flex_row_a} onClick={think_again}>
          <button className={styles.jump_small_button}>
            <span className={styles.jump_i_am_thinking} >我再想想</span>
          </button>
          <button className={styles.jump_small_button_4} onClick={start_test}>
            <span className={styles.jump_start_mock_exam}>开始模考</span>
          </button>
          <div className={styles.jump_rectangle} />
          <div className={styles.jump_rectangle_5} />
        </div>
      </div>
      )}
    </div>
    
    );
}
